import { Injectable } from '@angular/core';
import {
    ENotificationWsMessages,
    Establishment,
    ExchangeEvent,
    MedicationReconciliationStep,
    Notification,
    NotificationType,
    NotificationWsAnswer,
    NotificationWsRequest,
    Pathway,
    PatientIdentity,
    Profile,
    TransferPatientEvent,
    User,
    WithFinessEstablishment,
    ObjectId,
    ReleaseNote,
    DrugBalanceStep,
    IFilter,
    EDiscriminatorType
} from '@mapuilabs/hv-interfaces';
import PQueue from 'p-queue';
import { SocketService } from '@services/socket/socket.service';
import { translate } from '@jsverse/transloco';
import { UserService } from '@core/user/user.service';
import { PatientNamePipe } from '@shared/pipes/patient-name.pipe';
import { Router } from '@angular/router';
import { SnackbarService } from '@shared/services/snackbar/snackbar.service';
import { Subject } from 'rxjs';
import { PathwayNamePipe } from '@shared/pipes/pathway-name.pipe';
import dayjs from 'dayjs';

@Injectable()
export class NotificationsService {
    private _listening = false;
    private _queue: PQueue = new PQueue({ concurrency: 1 });

    // To refresh notifications list
    public notificationsChangedSubject = new Subject<void>();
    public notificationsChanged = this.notificationsChangedSubject.asObservable();

    constructor(
        private _socketService: SocketService,
        private _userService: UserService,
        private _patientNamePipe: PatientNamePipe,
        private _pathwayNamePipe: PathwayNamePipe,
        private _router: Router,
        private _snackbarService: SnackbarService
    ) {}

    listen() {
        if (!this._listening) {
            this._listening = true;
            this._socketService.socket.on(ENotificationWsMessages.Notification, (notif: Notification<any>) => {
                this._snackbarService.notify(notif, this);
                this.notificationsChangedSubject.next();
            });

            this._socketService.socket.on(ENotificationWsMessages.NotificationsRead, () => {
                this.notificationsChangedSubject.next();
            });
        }
    }

    async readAllNotifications(filters: IFilter[]): Promise<number> {
        return this._queue.add(async () => {
            return new Promise<number>((resolve, reject) => {
                this._socketService.socket.emit(
                    ENotificationWsMessages.NotificationsReadAll,
                    {
                        filters,
                        read: true
                    } as NotificationWsRequest.NotificationsRead,
                    (res: NotificationWsAnswer.NotificationsRead) => {
                        if (res.error) {
                            reject(res.error);
                        } else {
                            resolve(res.nb);
                        }
                    }
                );
            });
        });
    }

    async readNotifications<T>(read: boolean, notifications: Notification<T>[]): Promise<number> {
        const notificationToUpdate = notifications.filter((notif) => notif.read !== read);
        notificationToUpdate.forEach((notif) => (notif.read = read));

        return this._queue.add(async () => {
            return new Promise<number>((resolve, reject) => {
                this._socketService.socket.emit(
                    ENotificationWsMessages.NotificationsRead,
                    {
                        read,
                        ids: notificationToUpdate.map((n) => n._id)
                    } as NotificationWsRequest.NotificationsRead,
                    (res: NotificationWsAnswer.NotificationsRead) => {
                        if (res.error) {
                            reject(res.error);
                        } else {
                            resolve(res.nb);
                        }
                    }
                );
            });
        });
    }

    getSentence<T>(notification: Notification<T>): string {
        switch (notification.type) {
            case NotificationType.ExchangeFiles: {
                const data = notification.data as ExchangeEvent;
                return translate('NOTIFICATION_TYPES.EXCHANGE_FILES', {
                    establishmentName: (data.recipient as Establishment).establishmentName,
                    patientName: this._patientNamePipe.transform(data.identity as PatientIdentity)
                });
            }
            case NotificationType.ExchangeStatus: {
                const data = notification.data as ExchangeEvent;
                return translate('NOTIFICATION_TYPES.EXCHANGE_STATUS', {
                    status: notification.status ? translate(`STATUS.${notification.status.toUpperCase()}`) : '-',
                    patientName: this._patientNamePipe.transform(data.identity as PatientIdentity)
                });
            }
            case NotificationType.MedicationReconciliationToValidate:
            case NotificationType.DrugBalanceToValidate: {
                const user = (notification.creationPId as Profile).user as User;
                const pathway = ((notification.pathway as Pathway).parent || notification.pathway) as Pathway;
                const key = notification.type === NotificationType.DrugBalanceToValidate ? 'BMO' : 'CTM';
                return translate(`NOTIFICATION_TYPES.${key}_TO_VALIDATE`, {
                    username: user.fullName,
                    pathwayName: this._pathwayNamePipe.transform(pathway),
                    pathwayDate: dayjs(pathway.creationDate).format('DD/MM/YYYY'),
                    isConcil: pathway.type === EDiscriminatorType.MedRecPathway
                });
            }
            case NotificationType.ReleaseNote: {
                const data = notification.data as ReleaseNote;
                return translate(`NOTIFICATION_TYPES.RELEASE_NOTE`, {
                    version: data.version
                });
            }
            case NotificationType.PatientTransfer: {
                const estab = (notification.creationPId as Profile).establishment as Establishment;
                const data = notification.data as TransferPatientEvent;
                return translate('NOTIFICATION_TYPES.PATIENT_TRANSFER', {
                    establishmentName: estab.establishmentName,
                    patientName: this._patientNamePipe.transform(data.patient.identity as PatientIdentity)
                });
            }
            default:
                return '';
        }
    }

    async openNotification<T>(notification: Notification<T>) {
        let hasRedirection = false;
        switch (notification.type) {
            case NotificationType.ExchangeFiles:
            case NotificationType.ExchangeStatus: {
                const estabId = (this._userService.profile.establishment as Establishment)._id;
                const data = notification.data as ExchangeEvent;
                const path = estabId === (data.recipient as Establishment)._id ? 'received' : 'sent';
                hasRedirection = await this._router.navigate(['exchanges', path, data._id]);
                break;
            }
            case NotificationType.MedicationReconciliationToValidate: {
                const moduleId = (notification.data as MedicationReconciliationStep).output as ObjectId;
                const url = this._getPathwayModuleRedirection(
                    'medication-reconciliation',
                    moduleId,
                    notification.pathway as Pathway
                );
                hasRedirection = await this._router.navigateByUrl(url);
                break;
            }
            case NotificationType.DrugBalanceToValidate: {
                const moduleId = (notification.data as DrugBalanceStep).drugBalance as ObjectId;
                const url = this._getPathwayModuleRedirection(
                    'drug-balance',
                    moduleId,
                    notification.pathway as Pathway
                );
                hasRedirection = await this._router.navigateByUrl(url);
                break;
            }
            case NotificationType.PatientTransfer: {
                const data = notification.data as TransferPatientEvent;
                const currentEstab = data.patient.establishments.find((e) => e.current);
                const path =
                    currentEstab.finess ===
                    (this._userService.profile.establishment as WithFinessEstablishment).finessCode
                        ? 'patients'
                        : 'patients-transferred';
                hasRedirection = await this._router.navigate([path, data.patient._id]);
                break;
            }
            case NotificationType.ReleaseNote: {
                hasRedirection = await this._router.navigate(['settings/release-notes']);
                break;
            }
            default:
                break;
        }

        if (hasRedirection && !notification.read) {
            this.readNotifications(true, [notification]);
        }
    }

    private _getPathwayModuleRedirection(
        module: 'drug-balance' | 'medication-reconciliation',
        moduleId: ObjectId,
        pathway: Pathway
    ): string {
        const parentPathway = ((pathway as Pathway).parent || pathway) as Pathway;
        const path: { [k: string]: string } = {
            'octave-pathway': `pathways/octave/${parentPathway._id}/sub-pathway/${pathway?._id}/${module}/${moduleId}`,
            'medrec-in-out-pathway': `pathways/list/medrec-in-out/${parentPathway._id}/sub-pathway/${pathway?._id}/${module}/${moduleId}`,
            'passerelle-pathway': `pathways/passerelle/${parentPathway._id}/sub-pathway/${pathway?._id}/${module}/${moduleId}`,
            'medrec-pathway': `pathways/list/medrec/${pathway._id}/${module}/${moduleId}`
        };
        return path[parentPathway.type];
    }
}
